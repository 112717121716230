@use "sass:math";

// Standard Font Families

// usage examples
// ex1: @include font-light();
// ex2: @include font-light(16px);
// ex3: @include font-light(14px, 22px);

@mixin font-light($fontsize: "", $lineheight: "") {
    font-family: $sans-serif;
    font-weight: normal;
    font-weight: 300;

    @include font-size($fontsize, $lineheight);
}

// usage examples
// ex1: @include font-bold();
// ex2: @include font-bold(16px);
// ex3: @include font-bold(14px, 22px);

@mixin font-medium($fontsize: "", $lineheight: "") {
    font-family: $sans-serif;
    font-weight: 500;

    @include font-size($fontsize, $lineheight);
}

@mixin font-bold($fontsize: "", $lineheight: "") {
    font-family: $sans-serif;
    font-weight: 800;

    @include font-size($fontsize, $lineheight);
}

@mixin font-black($fontsize: "", $lineheight: "") {
    font-family: $sans-serif;
    font-weight: 1000;

    @include font-size($fontsize, $lineheight);
}

@mixin font-heading($fontsize: "", $lineheight: "") {
    font-family: $h1-heading-font;
    font-weight: 400;

    @include font-size($fontsize, $lineheight);
}
