@import "https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;800;1000&family=Yeseva+One&display=swap";

// body fonts
$serif: "Yeseva One", serif;
$sans-serif: "Mulish", sans-serif;
$sans-serif-bold: $sans-serif;
$sans-serif-condensed: $sans-serif;
$sans-serif-semibold: $sans-serif;
$sans-serif-regular: $sans-serif;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-condensed: 700;

// heading fonts
$heading-font: $serif;

// for more site granularity
$hero-heading-font: $sans-serif-bold;
$h1-heading-font: $heading-font;
$h2-heading-font: $sans-serif-bold;
$h3-heading-font: $sans-serif-bold;
$h4-heading-font: $sans-serif-bold;
$h5-heading-font: $sans-serif-bold;
$h6-heading-font: $sans-serif-bold;
$font-breadcrumb-active: $sans-serif-bold;

// Font Style
$font-style-italic: italic;
