@mixin type-menu-category-link-highlight {
    font-family: "Open Sans", arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    background: #008000;
    background-image: none;
    text-decoration: none;
    color: #fff;

    &:hover {
        color: #ededed;
    }
}

@mixin type-menu-category-link {
    font-family: "Open Sans", arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    background-image: none;
    text-decoration: none;
    color: #fff;

    &:hover {
        color: #ededed;
    }
}

@mixin type-h2-alt {
    font-size: 20px;

    @media (min-width) {
        font-size: 22px;
    }
}

.h2-alt {
    @include type-h2-alt;
}
