// --------------------------------
//  COLORS
// --------------------------------
// names taken from style guide

$brand-primary: #6f0003;
$brand-secondary: #9a1746;
$black: #000;
$dark-green: #203834;
$medium-green: #00684a;
$dark-gray: $dark-green;
$medium-gray: $medium-green;
$light-peach: #f2b9b3;
$lighter-beige: #faf5ec;
$light-gray: $medium-green;
$lighter-gray: $lighter-beige;
$white: #fff;
$accent1: #6f0003; // used in account, badges
$accent2: #9a1746;
$accent3: #00684a; // Add to Cart button
$accent4: #006246; // Add to Cart hover
$trans: transparent;

// consistent messaging colors
$error-color: #a00101;
$warning-color: #ffe544; // used in account
$success-color: #0c834a; // used in account

$body-text-color: $dark-green;
$sign-in-text: #f1c842;
$add-to-cart-text: #f1c842;
$logo-width-small: 234px;
$logo-height-small: 35px;
$logo-width-tablet: 235px;
$logo-height-tablet: 36px;
$logo-width-desktop: 300px;
$logo-height-desktop: 46px;
$body-line-height: 1.6;

// top banner
$promo-gradient-bg: $brand-primary;

// add to cart modal banner
$promo-gradient-bg-right: $brand-primary;

// Aliased variables used in global
$site-input-border-radius: 4px;
$site-btn-border-radius: 0;

// Button Fonts
$button-font: $sans-serif-bold;

// offset for the header search per brand
$header-search-offset: -70px;
$body-text-size: 15px;

// Borders & Divider Lines
$border-color: $dark-gray;
$border-brand-color: $brand-primary;
$border: 1px solid $border-color;
$border-thick: 2px solid $border-color;
$border-thick2: 5px solid $border-brand-color;
$border2-color: $light-gray;
$border2: 1px solid $border2-color;
$border3-color: $brand-secondary;
$border3: 1px solid $border3-color;
$border4: 1px solid $border-brand-color;
$border5-color: $lighter-gray;
$border5: 1px solid $border5-color;
$border6-color: $white;
$border6: 1px solid $border6-color;
$selected-swatch-border-color: $white;
$selected-swatch-border: 1px solid $selected-swatch-border-color;
$container-border-radius: 4px;

// Input Elements
$label-color: $medium-green;
$input-text-color: $medium-green;
$input-placeholder-color: $medium-green;
$input-form-caption-color: $medium-green;
$input-border-color: $medium-green;
$focus-border: 1px solid $medium-green;
$dropdown-arrow-icon-color: $medium-green;
