@import "fonts";

.br-m { display: none; }
.br-l { display: none; }
.br-xl { display: none; }
.br-s { display: block; }

@media screen and (min-width: 480px) {
    .br-s { display: none; }
    .br-l { display: none; }
    .br-xl { display: none; }
    .br-m { display: block; }
}

@media screen and (min-width: 768px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-xl { display: none; }
    .br-l { display: block; }
}

@media screen and (min-width: 1025px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-l { display: none; }
    .br-xl { display: block; }
}

.h1,
h1 {
    color: $brand-primary;
}

.h2,
h2,
.h3,
h3 {
    color: $accent2;
}

.h5,
h5,
.h6,
h6 {
    color: $medium-green;
    font-weight: 800;
}

.breadcrumb {
    li {
        &:last-child {
            a {
                color: $medium-green;
                font-weight: 800;
            }
        }
    }
}

.icon.svg-mobile-menu-dims {
    width: 30.92px !important;
    height: 19.69px !important;
}

.size.refinement ul.swatches a {
    color: $medium-green !important;
    border-color: $medium-green !important;
}

.size.refinement ul.swatches li.selected a {
    color: #fff !important;
    border-color: $medium-green !important;
    background-color: $medium-green !important;
}

.refinement ul.swatches li.unselectable a::after {
    background: $light-peach !important;
}

.size.refinement ul.swatches li.unselectable {
    opacity: 1;

    a {
        color: $light-peach !important;
        border-color: $light-peach !important;
    }
}

a.button-text {
    color: $medium-green !important;

    &:hover {
        color: $lighter-beige !important;
    }
}

.type-hero,
h1,
h2,
h3,
h5,
h6 {
    letter-spacing: 0 !important;
}

input[type="checkbox"]:checked + label::before {
    border: none !important;
}

.top-banner {
    height: fit-content;

    .icon.svg-search-dims {
        width: 20px;
        height: 28px;
        vertical-align: top;
    }

    .header-search button {
        padding: 0 0.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .top-banner {
        .icon.svg-account-dims,
        .icon.svg-cart-dims {
            width: 35px;
            height: 30px;
        }
    }
}

.top-banner .header-wrapper #navigation .menu-utility-user {
    @media screen and (min-width: 1025px) {
        top: -4px !important;
        right: 8.9rem;
    }
}

.button-grid {
    .button-style {
        &:first-child {
            button,
            a {
                min-width: 230px;
            }
        }
    }
}

.button-style {
    .large:not(.button-fancy-large) {
        min-width: 230px;
    }
}

#main {
    .button-style {
        code {
            background-color: $lighter-beige;
        }
    }

    code {
        background-color: #ececec;
    }

    p {
        .button-text {
            color: $medium-green;

            &:hover,
            &:active,
            &:focus {
                color: $lighter-beige;
            }
        }
    }

    a.button,
    button:not(.button-text) {
        font-size: 16px;
        border-radius: 0;
        padding: 10px 7%;
        text-transform: uppercase;

        &:not(.secondary, .tertiary) {
            font-family: $button-font;
            color: $white;

            &:hover {
                color: $white;
            }

            &:disabled,
            &:disabled:hover {
                color: $brand-primary;
                background: $light-peach;
                border: none;
            }
        }

        &.secondary {
            border-color: $brand-primary;
            color: $brand-primary;

            &:disabled,
            &:disabled:hover {
                border: 1px solid $light-peach;
                color: $light-peach;
                background-color: $white;
            }
        }

        &.tertiary {
            font-size: 16px;
            border-color: $dark-green;
            color: $dark-green;

            &.large {
                border-color: $brand-primary;
                color: $brand-primary;
            }

            &:disabled,
            &:disabled:hover {
                border: 1px solid $light-peach;
                color: $light-peach;
                background-color: $white;
            }
        }

        &.button-fancy-large {
            height: 40px;
            font-size: 16px;
            background: #eb0000;
            line-height: 2rem;
            border: none;

            &:not(.fake-checkout) {
                padding: 0 10%;
            }

            svg.icon.button-arrow-right {
                height: 35px;
                width: 35px;
                fill: $white;
            }

            &:hover {
                background: #f90404;
            }
        }
    }

    .type-hero {
        font-size: 74px;
    }

    .h1,
    h1 {
        font-size: 42px;
    }

    .h2,
    h2 {
        font-size: 32px;
    }

    .h3,
    h3 {
        font-size: 26px;
        text-transform: uppercase;
    }

    .h4,
    h4 {
        font-size: 22px;
    }

    .h5,
    h5 {
        font-size: 18px;
    }

    .h6,
    h6 {
        font-size: 17px;
    }
}

@media screen and (min-width: 768px) {
    #main {
        .type-hero {
            font-size: 74px;
        }

        .h1,
        h1 {
            font-size: 42px;
        }

        .h2,
        h2 {
            font-size: 32px;
        }

        .h3,
        h3 {
            font-size: 26px;
        }

        .h4,
        h4 {
            font-size: 22px;
        }

        .h5,
        h5 {
            font-size: 18px;
        }

        .h6,
        h6 {
            font-size: 17px;
        }
    }
}

.type-hero,
h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: 1000;
}

.headings {
    h4,
    .h4 {
        font-weight: 300;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}

.header-search::placeholder {
    content: "Keyword or Item #";
}

@include media-breakpoint-up(lg) {
    .menu-category {
        align-items: center;
        max-width: 97%;
        margin: 0 auto !important;
    }

    nav[role="navigation"] {
        padding: 0 7% !important;
    }

    .level-1 > li > a {
        max-width: 105px;
        margin: 0;

        span {
            line-height: 1.2;
        }
    }

    .primary-logo {
        top: -11px !important;
        margin-left: 8.9% !important;
    }

    .top-banner {
        background: #fff;
        padding: 2.25rem 0 0 !important;

        .header-wrapper {
            margin: 0;
            max-width: 100%;
            justify-content: space-around;

            .header-search {
                height: 32px;
                width: 41%;
                margin: 1.25rem 40% 0 !important;
                top: -52px !important;

                input[type="text"] {
                    height: 30px !important;
                }
            }

            #mini-cart {
                top: -6px !important;
                right: 6.7rem;
            }
        }
    }
}

b,
strong {
    font-weight: 800 !important;
}

.dark-green {
    color: $dark-green;
}

.dark-green-bg {
    background: $dark-green;
}

.medium-green {
    color: $medium-green;
}

.medium-green-bg {
    background: $medium-green;
}

.light-peach {
    color: $light-peach;
}

.light-peach-bg {
    background: $light-peach;
}

.lighter-beige {
    color: $lighter-beige;
}

.lighter-beige-bg {
    background: $lighter-beige;
}

.menu-utility-user .user-info .user-panel {
    right: -0.35rem !important;
    top: 45px;
}

@include media-breakpoint-down(md) {
    .primary-logo {
        top: 0 !important;
    }

    .top-banner {
        padding: 20px 25px 10px !important;
    }

    .top-banner .icon.svg-search-dims {
        width: 17px !important;
    }

    .top-banner .header-search button {
        min-height: 0 !important;
    }

    .header-search {
        margin: 1.2rem auto 0 !important;
        height: 28px !important;

        input[type="text"] {
            padding-left: 11px !important;
            height: 27px !important;
        }
    }
}

@media screen and (max-width: 400px) {
    .primary-logo {
        width: 206px !important;
    }
}

#mini-cart {
    @media screen and (max-width: 768px) {
        top: 21px !important;
        right: 26px !important;
    }
}

p {
    line-height: 1.6 !important;

    &.alt-p {
        @include font-medium(15px);
    }

    &.info {
        font-size: 13px;
        line-height: 1.54 !important;
    }
}

.pagination {
    li,
    a {
        line-height: 1 !important;
    }
}

ul,
ol {
    li {
        line-height: 32px !important;
        text-transform: none;
    }
}

.slick-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

svg.logo {
    height: $logo-height-desktop;
    width: $logo-width-desktop;
    color: black;
}

svg.logo-tablet {
    height: $logo-height-tablet;
    width: $logo-width-tablet;
}

svg.logo-mobile {
    height: $logo-height-small;
    width: $logo-width-small;
}

@media screen and (max-width: 1025px) {
    form#email-signup-footer {
        float: none;
        margin: 0 auto;

        button {
            height: 2.5rem;
        }
    }
}

div {
    &.badge-new,
    &.badge-newcolor,
    &.badge-sale,
    &.badge-rebate {
        border-radius: 50px !important;
        height: 52px;
        border: none;
        font-size: 17px;
        letter-spacing: 0;
        display: flex;
        width: 52px;
        align-items: center;
        justify-content: center;
    }

    &.badge-new {
        background: $brand-secondary;
    }

    &.badge-newcolor {
        background: $light-peach;
        font-size: 13px;
        padding-top: 0;
        color: $brand-primary;
    }

    &.badge-sale {
        background: #a00101;
    }

    &.badge-rebate {
        background: $medium-green;
        font-size: 12px;
        width: 52px;
        border-radius: 50px !important;
        height: 52px;
        border: none;
    }

    &.callout-badge {
        background: url("../images/svg-icons/callout-badge.svg") no-repeat;
        font-weight: 100;
        width: 218px;
        display: flex;
        height: 40px;
        text-transform: none;
        font-size: 17px;
        align-items: center;
        justify-content: center;
    }
}

.border3 {
    border: 1px solid $brand-secondary !important;

    + .divider-specs {
        .style-spec {
            color: $brand-secondary !important;
        }
    }
}

.border4 {
    border: 1px solid $brand-primary;
}

.shadow {
    box-shadow: 0 1px 3px 0 #0000004d !important;
}

.shadow-tile,
.shadow-tile-slider,
.footer-img-shadow {
    box-shadow: 0 0 3px 0 #c0c0c0 !important;
}

.shadow-minicart {
    box-shadow: -2px 3px 3px 0 #0000004d !important;
}

.menu-toggle {
    margin: 0 3% 0 0 !important;
    position: relative;
    top: -2px;
}

.dialogs {
    button {
        color: #fff !important;
        text-transform: none !important;
        font-weight: 800 !important;
    }
}

.content-builder h3 {
    color: $dark-green;
}

.content-builder-grid {
    input[type="checkbox"]:not(:checked) + label:not(.bv-fieldset-label-checkbox)::before,
    input[type="text"],
    .select-style {
        border-color: $dark-green !important;
    }

    select,
    input[type="text"] {
        color: $dark-green !important;
    }

    input {
        &:placeholder,
        &::placeholder { /* FF 18- */
            color: $dark-green !important;
        }

        &:input-placeholder,
        &::input-placeholder {
            color: $dark-green !important;
        }
    }
}

.tooltip .icon {
    margin: 0 3%;
}

.menu-utility-user .user-account {
    display: none !important;
}

.header-search input[type="text"] {
    color: $medium-green !important;
}

input[type="checkbox"]:not(:checked) + label:not(.bv-fieldset-label-checkbox)::before {
    border: 1px solid $light-peach !important;
}

input[type="checkbox"],
input[type="radio"] {
    &:focus-visible + label:not(.bv-fieldset-label-checkbox)::before {
        border: 2px solid $tab-selection-color !important;
        border-radius: 2px;
    }
}

select {
    color: $medium-green !important;
}

.user-info {
    .icon.svg-account-dims {
        fill: #037756;
    }
}

@include media-breakpoint-down(md) {
    .top-banner .header-wrapper {
        svg.icon {
            &.account,
            &.cart,
            &.mobile-menu {
                height: 30px;
                width: 30px;
            }
        }

        .signin-mobile-icon {
            width: 28px;
            height: 28px;
        }

        .account-menu-toggle {
            float: right !important;
            margin: 1px 8% !important;
        }

        .icon.svg-mobile-menu-dims {
            width: 29.92px !important;
            height: 18.69px !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .top-banner .header-wrapper {
        svg.icon {
            &.cart {
                height: 26px !important;
                width: 28px !important;
            }
        }

        .signin-mobile-icon {
            width: 27px !important;
            height: 21px !important;
        }
    }
}

#main h1.product-name {
    font-size: 28px;
}

@include media-breakpoint-down(md) {
    #main h1.product-name {
        font-size: 26px;
    }
}

// Add credit card modal
.ui-dialog .dialog-title {
    display: block;
    font-size: 30px;
    line-height: 32px;
}

.ui-dialog .edit-card {
    margin-top: 20px;
    max-width: 175px;
}

.ui-dialog .cancel-button {
    line-height: 1em;
    padding: 0.656rem 8%;
    max-height: 45px;
    max-width: 175px;
    font-size: 20px;
    border-width: 2px;
    text-align: center;
    border-color: #6f0003;
    background-color: #6f0003;
    color: #fff;
    border-radius: 10px;
}

.ui-dialog .cancel-button:hover {
    border-color: #9a1746;
    background-color: #9a1746;
    color: #fff;
}

// Saved card list on payment section

.payment-list li {
    display: flex;
    flex-direction: row;
}

.payment-list li div {
    margin-right: 20px;
}

.payment-list li .cc-exp {
    margin-left: 30px;
    margin-right: 0;
}

.payment-list .delete {
    font-size: 15px !important;
}

@include media-breakpoint-down(sm) {
    .account-module {
        padding: 15px !important;
    }

    .payment-list li div {
        margin-right: 8px;
        font-size: 14px;
    }

    .payment-list li .cc-exp {
        margin-left: 0;
    }

    .payment-list .delete {
        margin-left: 8px;
    }
}
